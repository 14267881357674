import { FC } from 'react';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import ActionEditorBase from '../ActionEditorBase';

const TickboxEditor: FC<EditorProps> = (props) => {
  return (
    <div data-cy="tickbox-editor">
      <ActionEditorBase {...props}></ActionEditorBase>

      <ConditionsProperties {...props} />

      <PreviewProperties {...props} enableDocumentNumbering />
    </div>
  );
};

export default TickboxEditor;
